/* 静态路由 */
export default [
  {
    path: '/',
    redirect: '/dataCenter',
    meta: { title: '数据中心' },
    component: () => import('@/components/layout/main'),
    children: [
      {
        path: '/dataCenter',
        meta: { title: '数据中心' },
        component: () => import('@/pages/dataCenter/index'),
      },
      {
        path: '/workOrderDetail',
        meta: { title: '在制工单详情' },
        component: () => import('@/pages/dataCenter/detailPages/workOrderDetail'),
      },
      {
        path: '/workOrderProgressDetail',
        meta: { title: '工单生产进度详情' },
        component: () => import('@/pages/dataCenter/detailPages/workOrderProgressDetail'),
      },
      {
        path: '/humiditySensitiveDetail',
        meta: { title: '湿敏详情' },
        component: () => import('@/pages/dataCenter/detailPages/humiditySensitiveDetail'),
      },
      {
        path: '/achievingRateDetails',
        meta: { title: '达成率详情' },
        component: () => import('@/pages/dataCenter/detailPages/achievingRateDetails'),
      },
      {
        path: '/productionScheduleDetail',
        meta: { title: '生产达成率详情' },
        component: () => import('@/pages/dataCenter/detailPages/productionScheduleDetail'),
      },
      {
        path: '/UPPHDetails',
        meta: { title: 'UPPH详情' },
        component: () => import('@/pages/dataCenter/detailPages/UPPHDetails'),
      },
      {
        path: '/FPYDetails',
        meta: { title: '直通率详情' },
        component: () => import('@/pages/dataCenter/detailPages/FPYDetails'),
      },
      {
        path: '/malYieldDetails',
        meta: { title: '不良详情' },
        component: () => import('@/pages/dataCenter/detailPages/malYieldDetails'),
      },
      {
        path: '/ESDDetails',
        meta: { title: 'ESD详情' },
        component: () => import('@/pages/dataCenter/detailPages/ESDDetails'),
      },
      {
        path: '/equipmentDetails',
        meta: { title: '设备详情' },
        component: () => import('@/pages/dataCenter/detailPages/equipmentDetails'),
      },
      {
        path: '/userDetails',
        meta: { title: '人员详情' },
        component: () => import('@/pages/dataCenter/detailPages/userDetails'),
      },
      {
        path: '/humitureDetails',
        meta: { title: '温湿度详情' },
        component: () => import('@/pages/dataCenter/detailPages/humitureDetails'),
      },
      {
        path: '/feederSorptionDetails',
        meta: { title: '供料器序列吸着信息详情' },
        component: () => import('@/pages/dataCenter/detailPages/feederSorptionDetails'),
      },
      {
        path: '/humitureBoard/index',
        meta: { title: '温湿度看板' },
        component: () => import('@/pages/dataCenter/humitureBoard/index.vue'),
      },
    ]
  }
]