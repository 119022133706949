import 'nprogress/nprogress.css'
import NProgress from 'nprogress'
import store from '@/store/index'
import login from './modules/login'
import staticRoute from './modules/staticRoute'
import { createRouter, createWebHashHistory } from 'vue-router'
import { ElMessage } from 'element-plus'
import { translate } from '@/utils/i18n'

// 创建路由
const router = createRouter({
  history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes: [
    ...login,
    ...staticRoute,
  ]
})

// 路由守卫
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : document.title
  NProgress.start() // 进度条

  // 判断是否为门户网站跳转过来的
  let fromDataCenter = null;
  if (to.query.fromDataCenter) {
    fromDataCenter = to.query.fromDataCenter;
    to.query.fromDataCenter = null;
    to.fullPath = to.path;
  } else if (to.redirectedFrom?.query?.fromDataCenter) {
    fromDataCenter = to.redirectedFrom.query.fromDataCenter;
    to.redirectedFrom.query.fromDataCenter = null;
    to.fullPath = to.path;
  }
  if (fromDataCenter) {
    fromDataCenter = fromDataCenter.replace(/aaannnddd/g, '&'); // 处理特殊字符
    const data = JSON.parse(fromDataCenter);
    localStorage.setItem('userInfo', JSON.stringify(data.userInfo)); // 缓存用户信息
    localStorage.setItem('roleId', data.userInfo.roleId); // 缓存角色id
    localStorage.setItem('accessToken', JSON.stringify(data.accessToken)); // 缓存accessToken信息
    localStorage.setItem('logo', data.companyInfo.logo); // 缓存logo
    localStorage.setItem('tenantName', data.companyInfo.tenantName); // 缓存tenantName
  }

  // 判断accessToken是否存在
  const accessToken = JSON.parse(localStorage.getItem('accessToken')) || ''
  if (accessToken && accessToken.value) {
    // 禁止返回登录页
    if (to.path == '/login') {
      if (from.path == '/login') {
        next({ path: staticRoute[0].path})  
      } else {
        next({ path: from.path, query: from.query })
      }
    } else {
      next()
    }
  } else {
    if (to.path != '/login') {
      next({ path: '/login' })
      ElMessage.warning(translate('登录状态已过期，请重新登录!'))
      store.dispatch('logOut')
    } else {
      next()
    }
  }
})

// 路由守卫
router.afterEach(() => {
  NProgress.done() // 进度条
})

export default router