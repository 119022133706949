/* i18n语言包（越南语） */

export default {
  'sys.operator.success': 'Hoạt động thành công',
  '业务异常': 'Kinh doanh bất thường',
  '网络请求失败，请稍候再试': 'Yêu cầu mạng không thành công, vui lòng thử lại trong thời gian chờ',
  '请输入公司关键词': 'Vui lòng nhập từ khóa công ty',
  '公司名不能为空': 'Tên công ty không được để trống',
  '请输入用户名': 'Vui lòng nhập tên người dùng',
  '用户名不能为空': 'Tên người dùng không được để trống',
  '请输入密码': 'Vui lòng nhập mật khẩu',
  '密码不能为空': 'Mật khẩu không được để trống',
  '长度在 6 到 20 个字符': 'Độ dài từ 6 đến 20 ký tự',
  '记住账号密码': 'Ghi nhớ mật khẩu tài khoản',
  '登录': 'Đăng nhập',
  '退出登录': 'Thoát Đăng nhập',
  '登录成功': 'Đăng nhập thành công',
  '暂无权限，请联系后台管理员': 'Không có quyền, vui lòng liên hệ với quản trị viên',
  '刷新': 'Làm mới',
  '抱歉！': 'Xin lỗi!',
  '当前页面不存在...': 'Trang hiện tại không tồn tại...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回上一个页面。': 'Vui lòng kiểm tra xem địa chỉ web bạn đã nhập có chính xác không hoặc nhấp vào nút bên dưới để quay lại trang trước đó.',
  '登录状态已过期，请重新登录!': 'Trạng thái đăng nhập đã hết hạn, vui lòng đăng nhập lại!',
  '返回上一个页面': 'Quay lại trang trước',
}
/*
  注意事项：
    1、中文的 ‘编号’ 翻译为越南语时会变成 ‘数量’ 的意思，所以要用 ‘编码’ 去翻译；
*/