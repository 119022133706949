export default {
  state: {
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || '',  // 用户信息
    authInfo: JSON.parse(localStorage.getItem('authInfo')) || {}, // 权限信息
    companyInfo: JSON.parse(localStorage.getItem('companyInfo')) || {},  // 公司信息
  },

  mutations: {

    // 设置用户信息
    SET_USER_INFO(state, data) {
      state.userInfo = data
    },

    // 设置权限信息
    SET_AUTH_INFO(state, data) {
      state.authInfo = data || {}
    },

    // 退出登录
    LOG_OUT(state) {
      state.userInfo = ''
    },

    // 设置公司信息
    SET_COMPANY_INFO(state, data) {
      state.companyInfo = data
    },

  },

  actions: {

    // 设置用户信息
    setUserInfo(context, data) {
      context.commit('SET_USER_INFO', data)
      localStorage.setItem('userInfo', JSON.stringify(data)) // 缓存用户信息
      localStorage.setItem('roleId', data.roleId) // 缓存角色id
      localStorage.setItem('accessToken', JSON.stringify({ time: Date.now(), value: data.accessToken })) // 缓存accessToken信息
    },

    // 设置权限信息
    setAuthInfo(context, data) {
      context.commit('SET_AUTH_INFO', data)
      localStorage.setItem('authInfo', JSON.stringify(data)) // 缓存权限信息
      localStorage.setItem('custNo', data.custNo || '');
    },

    // 退出登录
    logOut(context) {
      context.commit('LOG_OUT')
      localStorage.removeItem('userInfo')
      localStorage.removeItem('roleId')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('authInfo')
    },

    // 设置公司信息
    setCompanyInfo(context, data) {
      localStorage.setItem('companyInfo', JSON.stringify(data)) // 缓存公司信息
      context.commit('SET_COMPANY_INFO', data)
    },

  }
}