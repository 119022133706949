/* i18n语言包（简体中文） */

export default {
  'sys.operator.success': '操作成功',
  '业务异常': '业务异常',
  '网络请求失败，请稍候再试': '网络请求失败，请稍候再试',
  '请输入公司关键词': '请输入公司关键词',
  '公司名不能为空': '公司名不能为空',
  '请输入用户名': '请输入用户名',
  '用户名不能为空': '用户名不能为空',
  '请输入密码': '请输入密码',
  '密码不能为空': '密码不能为空',
  '长度在 6 到 20 个字符': '长度在 6 到 20 个字符',
  '记住账号密码': '记住账号密码',
  '登录': '登录',
  '退出登录': '退出登录',
  '登录成功': '登录成功',
  '暂无权限，请联系后台管理员': '暂无权限，请联系后台管理员',
  '刷新': '刷新',
  '抱歉！': '抱歉！',
  '当前页面不存在...': '当前页面不存在...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回上一个页面。': '请检查您输入的网址是否正确，或点击下面的按钮返回上一个页面。',
  '登录状态已过期，请重新登录!': '登录状态已过期，请重新登录!',
  '返回上一个页面': '返回上一个页面',
}