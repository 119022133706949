import { createI18n } from 'vue-i18n'
import zhCN from './zh-CN'  // i18n语言包（简体中文）
import en from './en'       // i18n语言包（英语）
import th from './th'       // i18n语言包（泰国语）
import vi from './vi'       // i18n语言包（越南语）

// 引入i18n语言文件
const messages = {
  'zh-CN': zhCN,
  'en': en,
  'th': th,
  'vi': vi,
}

// i18n配置
const i18n = createI18n( {
  legacy: false,          // 使用 Composition API 模式，则需要将其设置为false
  globalInjection: false, // 全局生效$t
  locale: localStorage.getItem('language') || 'zh-CN',
  messages,
})

// 切换语言
export function changeLanguage(language){
  i18n.locale = language
  localStorage.setItem('language', language)
}

//翻译
export function translate(text) {
  if(!text) return
  return i18n.global.t(text)
}

export default i18n