/* i18n语言包（英语） */

export default {
  'sys.operator.success': 'sys.operator.success',
  '业务异常': 'Business Exception',
  '网络请求失败，请稍候再试': 'Network request failed, please try again later',
  '请输入公司关键词': 'Please enter company keywords',
  '公司名不能为空': 'Company name cannot be empty',
  '请输入用户名': 'Please enter a name',
  '用户名不能为空': 'Name cannot be empty',
  '请输入密码': 'Please enter the password',
  '密码不能为空': 'Password cannot be empty',
  '长度在 6 到 20 个字符': 'Length between 6 and 20 characters',
  '记住账号密码': 'Remember account password',
  '登录': 'log on',
  '退出登录': 'Log out of login',
  '登录成功': 'Login succeeded',
  '暂无权限，请联系后台管理员': 'No permission currently, please contact the backend administrator',
  '刷新': 'Refresh',
  '抱歉！': 'Sorry!',
  '当前页面不存在...': 'The current page does not exist...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回上一个页面。': 'Please check if the website address you entered is correct, or click the button below to return to the previous page.',
  '登录状态已过期，请重新登录!': 'Login status has expired, please log in again!',
  '返回上一个页面': 'Go back to the previous page',
}