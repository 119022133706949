/* i18n语言包（泰国语） */

export default {
  'sys.operator.success': 'ความสำเร็จในการดำเนินงาน',
  '业务异常': 'ความผิดปกติทางธุรกิจ',
  '网络请求失败，请稍候再试': 'คำขอเครือข่ายล้มเหลวโปรดรอสักครู่เพื่อลองอีกครั้ง',
  '请输入公司关键词': 'กรุณาใส่คำหลักของบริษัท',
  '公司名不能为空': 'ชื่อบริษัทต้องไม่ว่างเปล่า',
  '请输入用户名': 'กรุณาใส่ชื่อผู้ใช้',
  '用户名不能为空': 'ชื่อผู้ใช้ไม่สามารถว่างเปล่า',
  '请输入密码': 'กรุณาใส่รหัสผ่าน',
  '密码不能为空': 'รหัสผ่านต้องไม่ว่างเปล่า',
  '长度在 6 到 20 个字符': 'ความยาวตั้งแต่ 6 ถึง 20 ตัวอักษร',
  '记住账号密码': 'จำรหัสผ่านของบัญชี',
  '登录': 'ล็อกอิน',
  '退出登录': 'ออกจากระบบ',
  '登录成功': 'ล็อกอินสำเร็จ',
  '暂无权限，请联系后台管理员': 'ไม่มีสิทธิ์ชั่วคราวโปรดติดต่อผู้ดูแลระบบหลังบ้าน',
  '刷新': 'รีเฟรช',
  '抱歉！': 'ขอโทษนะ!',
  '当前页面不存在...': 'ไม่มีหน้าปัจจุบัน...',
  '请检查您输入的网址是否正确，或点击下面的按钮返回上一个页面。': 'โปรดตรวจสอบว่า URL ที่คุณป้อนนั้นถูกต้องหรือคลิกที่ปุ่มด้านล่างเพื่อกลับไปยังหน้าก่อนหน้า',
  '登录状态已过期，请重新登录!': 'สถานะการเข้าสู่ระบบหมดอายุแล้ว กรุณาเข้าสู่ระบบใหม่!',
  '返回上一个页面': 'กลับไปที่หน้าก่อนหน้า',
}